import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectAllFields } from "./formidableSlice";

import BackLink from "../../components/Backlink";
import Field from "./Field";
import Section from "./Section";

import { Button } from "../../components/fields";

export const Page = () => {
  const { formId, page: currentPage = 1 } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [pageErrors, setPageErrors] = useState(false);
  const [pageValidated, setPageValidated] = useState(false);

  // Get all fields (memoized)
  const fields = useSelector((state) => selectAllFields(state));

  // Fields on this page
  const fieldsOnThisPage = useMemo(() => {
    return fields.filter((field) => field.page == currentPage);
  }, [fields, currentPage]);

  // Fields on this page with errors
  const fieldsWithErrors = useMemo(() => {
    return fieldsOnThisPage.filter((field) => field.error) ?? [];
  }, [fieldsOnThisPage]);

  // Page breaks
  const pageBreakFields = useMemo(() => {
    return fields.filter((field) => field.type === "break");
  }, [fields]);

  // Next page that is no conditionally hidden
  const nextPage = pageBreakFields.find((field) => {
    return field.page >= parseInt(currentPage) && !field.hidden;
  });

  // Previous link
  const [previousPage, setPreviousPage] = useState(`/${formId}/`);

  // Set the previous page link

  // Check for errors
  useEffect(() => {
    if (fieldsWithErrors.length > 0) {
      return setPageErrors(true);
    }
    return setPageErrors(false);
  }, [fieldsWithErrors]);

  // Button handler
  const buttonHandler = (e) => {
    e.target.blur();

    if (!pageValidated) setPageValidated(true);

    if (!pageErrors) {
      setPageValidated(false);
      setPageErrors(false);
      setPreviousPage(location.pathname);
      navigate(`/${formId}/page/${e.target.value}`);
    }
    return;
  };

  return (
    <>
      <BackLink to={previousPage} />

      {fields.map((field, key) => {
        return (
          <Section fieldId={field.id} key={key} page={currentPage}>
            <Field
              fieldId={field.id}
              fieldValidated={pageValidated}
              page={currentPage}
            />
          </Section>
        );
      })}

      {nextPage && (
        <Button onClick={buttonHandler} value={nextPage.value}>
          Save and continue {nextPage.id}
        </Button>
      )}

      {!nextPage && (
        <Button onClick={buttonHandler} value="../summary">
          Save and continuex
        </Button>
      )}
    </>
  );
};

export default Page;
