import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectFieldById } from "./formidableSlice";
import { Fieldset } from "nhsuk-react-components";

export const Section = (props) => {
  // Set current section ID

  // Check if in current section

  const { children, fieldId, page: currentPage } = props;

  // Get the field data from formidableSlice
  const { name, field_options, hidden, page, type } = useSelector((state) =>
    selectFieldById(state, fieldId)
  );

  const { in_section } = field_options ?? 0;

  if ((in_section === "0" && type !== "divider") || type === "break")
    return <>{children}</>;

  return (
    <Fieldset>
      {type === "divider" && !hidden && page === currentPage && (
        <Fieldset.Legend size="m">{name}</Fieldset.Legend>
      )}
      {children}
    </Fieldset>
  );
};

export default memo(Section);
