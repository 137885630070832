import React, { useEffect, useMemo } from "react";
import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import { useGetFormQuery, useGetFieldsQuery } from "./formidableSlice";
import { resetForm } from "./formSlice";

export const Form = () => {
  // Load form ID from URL
  const { formId } = useParams();

  // Load Formidable Form Data
  const { data: form, isSuccess } = useGetFormQuery(formId);

  // Load Formidable Field Data
  const { data: fieldsData, isSuccess: isFieldsSuccess } =
    useGetFieldsQuery(formId);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Set the initial form state
  useEffect(() => {
    navigate(`/${formId}`);
  }, [form]);

  // Set the initial fields state
  useEffect(() => {
    if (isFieldsSuccess) {
      dispatch(resetForm({ formId, fieldsData }));
    }
  }, [isFieldsSuccess]);

  if (!isSuccess) return <p>Loading form.....</p>;

  return (
    <>
      <div className="nhsuk-width-container">
        <main
          className="nhsuk-main-wrapper nhsuk-main-wrapper--s"
          id="maincontent"
          role="main"
        >
          <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-two-thirds">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Form;
