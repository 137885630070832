import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.scss";
import "../node_modules/nhsuk-frontend/dist/nhsuk.min.js";

import Layout from "./app/Layout";

// import { FootfallRooms } from "./features/rooms/FootfallRooms";
// import { SingleRoomPage } from "./features/rooms/SingleRoomPage";
import { FormsList } from "./features/formidable/FormsList";
import {
  Form,
  Page,
  Success as FormSuccess,
  Summary as FormSummary,
} from "./features/formidable";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* <Route index element={<FootfallRooms />} /> */}
            <Route path="forms" element={<FormsList />} />
            <Route path="/:formId" element={<Form />}>
              <Route path="" element={<Page />} />
              <Route path="page/:page" element={<Page />} />
              <Route path="summary" element={<FormSummary />} />
              <Route path="success" element={<FormSuccess />} />
            </Route>
            {/* <Route path="room/:roomSlug" element={<SingleRoomPage />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
