/*
 * Create a set of fields for patient identifaction classnames
 * Use SP Forms field ids to ensure compatibility with dashboard
 */
const defaultField = {
  default_value: "",
  description: "",
  field_options: {},
  options: [],
  required: 1,
};

const defaultFieldOptions = {
  any_all: "all",
  blank: "This field cannot be blank.",
  calc: "",
  classes: "",
  hide_field: [],
  hide_field_cond: [],
  hide_opt: [],
  in_section: "",
  max: 0,
  maxnum: 0,
  minnum: 0,
  read_only: 0,
  show_hide: "show",
  step: 0,
};

export const proxySelectFieldAttributes = [
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "proxy_Select",
    name: "Who are you completing this form for?",
    options: [
      {
        label: "Myself",
        value: "non_proxy",
      },
      {
        label: "Someone else",
        value: "proxy",
      },
    ],
    type: "radio",
    // default_value: "non_proxy",
  },
];

export const proxyFieldsAttributes = [
  {
    ...defaultField,
    id: "proxy_relationship_break",
    name: "Save and continue",
    type: "break",
    field_options: {
      ...defaultFieldOptions,
      any_all: "all",
      hide_field: ["proxy_Select"],
      hide_field_cond: ["=="],
      hide_opt: ["non_proxy"],
      show_hide: "hide",
    },
  },
  // What is your relationship to the patient
  {
    id: "proxy_relationship",
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    name: "What is your relationship with the patient?",
    options: [
      {
        label: "Parent",
        value: "parent",
      },
      {
        label: "Guardian",
        value: "guardian",
      },
      {
        label: "Spouse",
        value: "spouse",
      },
      {
        label: "Carer",
        value: "carer",
      },
      {
        label: "Son",
        value: "son",
      },
      {
        label: "Sibling",
        value: "sibling",
      },
      {
        label: "Other",
        value: "other",
      },
    ],
    type: "radio",
  },
  // Add page break
  {
    ...defaultField,
    id: "proxy_identity_break",
    name: "Save and continue",
    type: "break",
    field_options: {
      ...defaultFieldOptions,
      any_all: "all",
      hide_field: ["proxy_Select"],
      hide_field_cond: ["=="],
      hide_opt: ["non_proxy"],
      show_hide: "hide",
    },
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "proxy_names_section",
    name: "What is your name?",
    description: "",
    type: "divider",
  },
  {
    ...defaultField,
    id: "proxy_firstnames",
    name: "First names",
    description: "",
    type: "text",
    field_options: {
      ...defaultFieldOptions,
      in_section: "proxy_names_section",
    },
  },
  {
    ...defaultField,
    id: "proxy_lastname",
    name: "Last name",
    description: "",
    type: "text",
    field_options: {
      ...defaultFieldOptions,
      in_section: "proxy_names_section",
    },
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions, start_year: "120", end_year: "" },
    id: "proxy_dob",
    name: "What is your date of birth?",
    description: "For example, 31 3 1980",
    type: "date",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "proxy_sex",
    name: "What is your sex?",
    description: "As shown on your medical record",
    options: [
      {
        label: "Male",
        value: "M",
      },
      {
        label: "Female",
        value: "F",
      },
      {
        label: "Other",
        value: "1",
      },
    ],
    type: "radio",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "proxy_postcode",
    name: "What is your postcode?",
    description: "",
    type: "text",
  },
  // Add page break
  {
    ...defaultField,
    id: "proxy_contact_break",
    name: "Save and continue",
    type: "break",
    field_options: {
      ...defaultFieldOptions,
      any_all: "all",
      hide_field: ["proxy_Select"],
      hide_field_cond: ["=="],
      hide_opt: ["non_proxy"],
      show_hide: "hide",
    },
  },
  // Section
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "proxy_contact_section",
    name: "What are your contact details?",
    description:
      "As you have said you are completing this on behalf of someone else, we need your contact details.",
    type: "divider",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "contact_email",
    name: "What is your email?",
    description: "As shown on your medical record",
    type: "email",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "contact_phone",
    name: "Telephone number",
    description: "",
    type: "phone",
  },
  // Add page break
  {
    ...defaultField,
    id: "patient_identity_break",
    name: "Save and continue",
    type: "break",
    field_options: {
      ...defaultFieldOptions,
      any_all: "all",
      hide_field: ["proxy_Select"],
      hide_field_cond: ["=="],
      hide_opt: ["non_proxy"],
      show_hide: "hide",
    },
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "patient_names_section",
    name: "What is the patient’s name?",
    description: "",
    type: "divider",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "patient_firstnames",
    name: "First names",
    description: "",
    type: "text",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "patient_lastname",
    name: "Last name",
    description: "",
    type: "text",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions, start_year: "120", end_year: "" },
    id: "patient_dob",
    name: "What is the patient's date of birth?",
    description: "For example, 31 3 1980",
    type: "date",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "patient_sex",
    name: "What is the patient's sex?",
    description: "As shown on your medical record",
    type: "radio",
    options: [
      {
        label: "Male",
        value: "M",
      },
      {
        label: "Female",
        value: "F",
      },
      {
        label: "Other",
        value: "1",
      },
    ],
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "patient_postcode",
    name: "What is the patient's postcode?",
    description: "",
    type: "text",
  },
  {
    ...defaultField,
    id: "proxy_break",
    name: "Save and continue",
    type: "break",
    field_options: {
      ...defaultFieldOptions,
    },
  },
  // End of array
];

export const nonProxyFieldsAttributes = [
  // Section
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "non_proxy_names_section",
    name: "What is your name?",
    description: "",
    type: "divider",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "non_proxy_firstnames",
    name: "First names",
    description: "",
    type: "text",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "non_proxy_lastname",
    name: "Last name",
    description: "",
    type: "text",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions, start_year: "120", end_year: "" },
    id: "non_proxy_dob",
    name: "What is your date of birth?",
    description: "For example, 31 3 1980",
    type: "date",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "non_proxy_sex",
    name: "What is your sex?",
    description: "As shown on your medical record",
    type: "radio",
    options: [
      {
        label: "Male",
        value: "M",
      },
      {
        label: "Female",
        value: "F",
      },
      {
        label: "Other",
        value: "1",
      },
    ],
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "non_proxy_postcode",
    name: "What is your postcode?",
    description: "",
    type: "text",
  },
  {
    ...defaultField,
    id: "non_proxy_contact_break",
    name: "Save and continue",
    type: "break",
    field_options: {
      ...defaultFieldOptions,
    },
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "non_proxy_contact_section",
    name: "What are your contact details?",
    description: "",
    type: "divider",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "non_proxy_email",
    name: "What is your email?",
    description: "As shown on your medical record",
    type: "email",
  },
  {
    ...defaultField,
    field_options: { ...defaultFieldOptions },
    id: "non_proxy_phone",
    name: "Telephone number",
    description: "",
    type: "phone",
  },
  {
    ...defaultField,
    id: "non_proxy_break",
    name: "Save and continue",
    type: "break",
    field_options: {
      ...defaultFieldOptions,
    },
  },
];

const proxyFieldIds = [
  "patient_firstnames",
  "patient_lastname",
  "patient_postcode",
  "patient_dob",
  "patient_sex",
  "proxy_relationship",
  "proxy_postcode",
  "proxy_firstnames",
  "proxy_lastname",
  "proxy_dob",
  "proxy_sex",
  "contact_phone",
  "contact_email",
];
