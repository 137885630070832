import React, { memo } from "react";
import { Select as NHSSelect } from "nhsuk-react-components";

export const Select = (props) => {
  const { min, max, options, rows, step, value, ...rest } = props;
  return (
    <NHSSelect {...rest}>
      {options.map((option, key) => {
        return (
          <NHSSelect.Option
            value={option.value}
            key={key}
            selected={option.value === value}
          >
            {option.label}
          </NHSSelect.Option>
        );
      })}
    </NHSSelect>
  );
};

export default memo(Select);
