import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const Layout = () => {
  return (
          <Outlet />
  );
};

export default Layout;
