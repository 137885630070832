import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

import {
  nonProxyFieldsAttributes,
  proxyFieldsAttributes,
  proxySelectFieldAttributes,
} from "./proxyFieldsAttributes";

import { resetForm, updateForm } from "./formSlice";

const fieldsAdapter = createEntityAdapter();

const initialState = fieldsAdapter.getInitialState();

// The following fields are supported (exclude page breaks)
const supportedFields = [
  "checkbox",
  "divider",
  "email",
  "number",
  "phone",
  "radio",
  "text",
];

const fieldsSlice = createSlice({
  name: "fields",
  initialState: initialState,
  reducers: {
    // Clear all field data
    resetFields() {
      return initialState;
    },

    // Will need to remove the current proxy / non-proxy fields and re-index
    updateProxy(state, action) {
      // Perform any field state changes for labels
    },

    // old stuff
    resetFieldData(state, action) {
      // Add sort order and page numbers
    },
    // Update any field attribute {key: value}
    updateField(state, action) {
      fieldsAdapter.upsertOne(state, action.payload);
    },
    updateVaidationError(state, action) {
      fieldsAdapter.upsertOne(state, action.payload);
    },
    updateVaidationError() {},
  },
  // Add form state actions here
  extraReducers: (builder) => {
    builder.addCase(resetForm, (state, action) => {
      const { fieldsData } = action.payload;
      return fieldsAdapter.setAll(
        initialState,
        fieldsData.map((fieldData) => {
          const { id, default_value, type } = fieldData;
          const value = type === "checkbox" ? [] : "";
          return {
            id: id,
            error: false,
            hidden: false,
            value: default_value?.length > 0 ? default_value : value,
          };
        })
      );
    });
  },
});

export default fieldsSlice.reducer;

export const { resetFields, setFieldData, updateProxy, updateField } =
  fieldsSlice.actions;

export const {
  selectAll: selectAllFields,
  selectById: selectFieldById,
  selectIds: selectFieldIds,
  // Pass in a selector that returns the posts slice of state
} = fieldsAdapter.getSelectors((state) => state.fields);

export const selectFieldsByType = createSelector(
  [selectAllFields, (state, type) => type],
  (fields, type) => fields.filter((field) => field.type === type)
);

export const selectFieldsByPage = createSelector(
  [selectAllFields, (state, page) => page],
  (fields, page) => fields.filter((field) => field.page == page)
);

export const selectAllSupportedFields = createSelector(
  selectAllFields,
  (fields) => fields.filter((field) => supportedFields.includes(field.type))
);

export const selectAllPageFields = createSelector(selectAllFields, (fields) =>
  fields.filter((field) => field.type === "break")
);

export const selectLookupFields = createSelector(
  [selectAllFields, (state, hideField) => hideField],
  (fields, hideField) => fields.filter((field) => hideField.includes(field.id))
);
