import React, { memo } from "react";
import { Fieldset, Radios } from "nhsuk-react-components";

export const Radio = (props) => {
  const { name: label, options, value } = props;
  return (
    <Fieldset>
      <Fieldset.Legend>{label}</Fieldset.Legend>
      <Radios {...props}>
        {options.map((option, key) => {
          return (
            <Radios.Radio
              value={option.value}
              key={key}
              checked={option.value === value}
            >
              {option.label}
            </Radios.Radio>
          );
        })}
      </Radios>
    </Fieldset>
  );
};

export default memo(Radio);
