import React, { memo } from "react";
import { Input as NHSInput } from "nhsuk-react-components";

export const Input = (props) => {
  const { description, name, type, field_options } = props;

  const { maxnum, minnum, step } = field_options ?? {};

  if (type === "number") {
    const { options, rows, ...numberProps } = props;
    return (
      <NHSInput
        label={name}
        hint={description}
        max={maxnum}
        min={minnum}
        step={step}
        {...props}
      />
    );
  }
  // Remove unwanted fields

  return <NHSInput label={name} hint={description} {...props} />;
};

export default memo(Input);
