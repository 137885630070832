import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formId: 0,
  isProxy: false,
  isNonProxy: false,
  isValidated: false,
  isSubmitted: false,
  isError: false,
};

const formSlice = createSlice({
  name: "form",
  initialState: initialState,
  reducers: {
    resetForm: (state, action) => {
      const { fieldsData, ...rest } = action.payload;
      return { ...state, ...rest };
    },
    updateForm(state, action) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {},
});

export default formSlice.reducer;

export const { resetForm, updateForm } = formSlice.actions;
