import {
  createSlice,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { apiSlice } from "../api/apiSlice";

// Fake loading proxy fields
import {
  nonProxyFieldsAttributes,
  proxyFieldsAttributes,
  proxySelectFieldAttributes,
} from "./proxyFieldsAttributes";

import { resetForm } from "./formSlice";

const formsAdapter = createEntityAdapter();
const formsInitialState = formsAdapter.getInitialState();

const transformFormidableResponse = (responseData) => {
  var isProxy = false;
  var isNonProxy = false;

  const ids = Object.keys(responseData);

  // Transorm the data to remove any unsupported fields and features
  const transformedResponse = ids.map((key) => {
    const { default_value, options, field_options, type } = responseData[key];

    // Create separate values for all option fields
    responseData[key].options = (() => {
      if (!options) {
        return options;
      }
      return options.map((option) => {
        return {
          label: option.label ?? option ?? "",
          value: option.value ?? option ?? "",
        };
      });
    })();

    responseData[key].type = (() => {
      const { classes } = field_options;
      if (type !== "divider") {
        return type;
      }
      if (classes.indexOf("sp_patient_identification_non_proxy") >= 0)
        return (isNonProxy = true);

      if (classes.indexOf("sp_patient_identification") >= 0)
        return (isProxy = true);

      return type;
    })();

    return responseData[key];
  });

  if (isProxy)
    return [
      ...proxySelectFieldAttributes,
      ...proxyFieldsAttributes,
      ...nonProxyFieldsAttributes,
      ...transformedResponse,
    ];

  if (isNonProxy) return [...nonProxyFieldsAttributes, ...transformedResponse];

  return transformedResponse;
};

const baseUrl = `https://${process.env.REACT_APP_FORMS_ENDPOINT}`;
//const baseUrl = `http://${process.env.REACT_APP_SITE_ENDPOINT}`;

export const formidableApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getForms: build.query({
      // Will need to add more parameters to load other forms!
      query: () => `${baseUrl}/wp-json/frm/v2/forms/?order_by=name`,
      transformResponse: (responseData) => {
        return formsAdapter.setAll(formsInitialState, responseData);
      },
    }),
    getForm: build.query({
      query: (formId) => `${baseUrl}/wp-json/frm/v2/forms/${formId}`,
    }),
    getFields: build.query({
      query: (formId) => `${baseUrl}/wp-json/frm/v2/forms/${formId}/fields`,
      transformResponse: (responseData) => {
        return transformFormidableResponse(responseData);
      },
    }),
    postForm: build.mutation({
      query: (formData) => ({
        url: `${baseUrl}/dashboard/api/thread`,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const {
  useGetFormsQuery,
  useGetFormQuery,
  useGetFieldsQuery,
  usePostFormMutation,
} = formidableApiSlice;

const fieldsAdapter = createEntityAdapter();
const fieldsInitialState = fieldsAdapter.getInitialState();

const formidableSlice = createSlice({
  name: "formidable",
  initialState: fieldsInitialState,
  reducers: {
    updateField(state, action) {
      fieldsAdapter.updateOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetForm, (state, action) => {
      const { fieldsData } = action.payload;
      var sortOrder = 1;
      var currentPage = 1;

      return fieldsAdapter.setAll(
        fieldsInitialState,
        fieldsData.map(({ ...field }) => {
          const { default_value, type } = field;
          const value = type === "checkbox" ? [] : "";
          field.value = default_value?.length > 0 ? default_value : value;

          field.name = field.name + " [" + field.id + "]";
          field.error = false;
          field.hidden = true;
          // re-order fields
          field.field_order = sortOrder++;
          // Set the current page
          field.page = currentPage;

          if (field.type === "break") {
            currentPage++;
            field.value = currentPage;
          }

          return field;
        })
      );
    });
  },
});

export default formidableSlice.reducer;

export const { updateField } = formidableSlice.actions;

export const {
  selectAll: selectAllFields,
  selectById: selectFieldById,
  selectIds: selectFieldIds,
  // Pass in a selector that returns the posts slice of state
} = fieldsAdapter.getSelectors((state) => state.formidable);

export const selectFieldsByPage = createSelector(
  [selectAllFields, (state, page) => page],
  (fields, page) => fields.filter((field) => field.page == page)
);

export const selectAllPageFields = createSelector(selectAllFields, (fields) =>
  fields.filter((field) => field.type === "break")
);
