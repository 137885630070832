import { useSelector } from "react-redux";

export const useFieldCondition = (fieldId) => {
  // Global state
  const { entities: fields } = useSelector((state) => state.formidable);

  const { field_options } = fields[fieldId];

  const {
    any_all: anyAll,
    hide_field: hideField,
    hide_field_cond: hideFieldConds,
    hide_opt: hideOpts,
    show_hide: showHide,
  } = field_options;

  // Check if the section is hidden
  const { in_section = 0 } = field_options;

  if (in_section !== 0 && fields[in_section]?.hidden) return true;

  // If no conditional logic, return false
  if (hideField?.length === 0) {
    return false;
  }

  var conditionMet = false;

  var isHidden = showHide === "show" ? true : false;

  const evaluate = (param1, param2, operator) => {
    let param1Array = Array.isArray(param1);
    switch (operator) {
      case "==":
        if (param1Array) {
          return param1.includes(param2);
        }
        return param1 === param2;
      case "!=":
        if (param1Array) {
          return !param1.includes(param2);
        }
        return param1 !== param2;
      // other operators
      default:
        return false;
    }
  };

  if (!hideField || hideField.length === 0) return conditionMet;

  for (let i = 0; i < hideField.length; i++) {
    let param1 = fields[hideField[i]]?.value;
    let param2 = hideOpts[i];
    let operator = hideFieldConds[i];

    // Evaluate the conditions
    conditionMet = evaluate(param1, param2, operator);
    // If one condition met and any, can break out
    if (conditionMet && anyAll === "any") {
      break;
    }
    // If all conditions must be met and any all break out
    if (!conditionMet && anyAll === "all") {
      break;
    }
  }

  if (conditionMet) isHidden = !isHidden;

  return isHidden;
};
