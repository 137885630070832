import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAllFields, usePostFormMutation } from "./formidableSlice";

import { Button } from "../../components/fields";
import BackLink from "../../components/Backlink";
import SummaryList from "../../components/FormSummary";

const Summary = () => {
  const [previousPage, setPreviousPage] = useState("");

  const { formId } = useParams();

  const navigate = useNavigate();

  const [postForm, { isLoading }] = usePostFormMutation();

  const { entities: fieldData } = useSelector((state) => state.formidable);

  const fields = useSelector((state) => selectAllFields(state));

  const pages = fields.filter((field) => {
    if (field.type === "break" && !field.hidden) {
      return field.page;
    }
  });

  const summaryFields = pages.map((currentPage) => {
    return fields.filter((field) => field.page === currentPage.page);
  });

  const patientInfo = {
    patient_firstnames: fieldData["patient_firstnames"],
    patient_lastname: fieldData["patient_lastname"],
    name: `${fieldData["patient_firstnames"]} ${fieldData["patient_lastname"]}`,
    dob: fieldData["patient_dob"],
    email: fieldData["patient_firstnames"],
    phone: fieldData["phone"],
    title: `${fieldData["patient_firstnames"]}, ${fieldData["patient_lastname"]}; DOB ${fieldData["patient_dob"]}`,
    sex: fieldData["contact_sex"],
    postcode: fieldData["patient_postcode"],
  };

  // Move this in to the formidable slice
  const formData = {
    status: "new",
    proxy: 0,
    form_id: formId,
    host: process.env.REACT_APP_FORMS_ENDPOINT,
    practice_proxy_id: 0,
    fields: fields,
    submitter: "",
    patient_info: patientInfo,
  };

  const buttonHandler = async () => {
    try {
      await postForm(formData);
      navigate(`https://formff.footfallpreview.co.uk/wp-admin/admin-ajax.php`);
    } catch (err) {
      console.error("Failed to save the post: ", err);
    }
  };

  return (
    <>
      <BackLink to={previousPage} />
      <h2>Form summary</h2>
      <p>Results...</p>
      {summaryFields.map((pageFields) => (
        <SummaryList list={pageFields} />
      ))}
      <Button onClick={buttonHandler}>Submit</Button>
    </>
  );
};

export default Summary;
