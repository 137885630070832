import { configureStore } from "@reduxjs/toolkit";

import { apiSlice } from "../features/api/apiSlice";

import formidableReducer from "../features/formidable/formidableSlice";
import formReducer from "../features/formidable/formSlice";
import fieldsReducer from "../features/formidable/fieldsSlice";

export default configureStore({
  reducer: {
    form: formReducer,
    formidable: formidableReducer,
    fields: fieldsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
