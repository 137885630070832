import React from "react";
import { SummaryList, BodyText } from "nhsuk-react-components";

const FormSummary = (props) => {
  const { list } = props;
  return (
    <SummaryList>
      {list.map((item, key) => {
        return (
          <SummaryList.Row>
            <SummaryList.Key>
              {item.name} {item.type}
            </SummaryList.Key>
            <SummaryList.Value>{item.value}</SummaryList.Value>
            <SummaryList.Actions>
              <a href="#">Change</a>
            </SummaryList.Actions>
          </SummaryList.Row>
        );
      })}
    </SummaryList>
  );
};

export default FormSummary;
