import React, { memo } from "react";
import { DateInput, Fieldset, Input } from "nhsuk-react-components";

export const Date = (props) => {
  const { description, error, name, type, field_options } = props;

  const labelProps = { size: "m" };

  return (
    <DateInput
      error={error}
      hint={description}
      label={name}
      labelProps={labelProps}
    >
      <DateInput.Day error={false} />
      <DateInput.Month />
      <DateInput.Year />
    </DateInput>
  );
};

export default Date(Input);
