import React from "react";
import { useSelector } from "react-redux";
import { selectFieldById } from "./formidableSlice";

export const useFieldValidation = (fieldId) => {
  const { entities: fields } = useSelector((state) => state.formidable);

  const { field_options, required, type, value } = fields[fieldId];

  const {
    blank,
    minnum: min,
    maxnum: max,
    start_year,
    end_year,
  } = field_options ?? {};

  const isRequired = !!required === true; // maybe number or string

  var validationError = "";

  // Default validation will check for blank required field
  const defaultValidation = () => {
    if (isRequired && value.length === 0)
      return blank ?? "This field must not be blank.";
    // if no errors return empty string
    //dispatch(updateForm(true));
    return "";
  };

  // Check number for min and max
  const validateNumberField = () => {
    if (isRequired && value.length === 0)
      return blank ?? "A valid number is is required.";
    // Check if number is valid
    if (Number.isInteger(value)) return "The number is invalid.";
    // Check the minumum number3

    if (min.length > 0 && Number(min) > Number(value))
      return `The number must be ${min} or more.`;
    // Check the maximum number
    if (max.length > 0 && Number(max) < Number(value))
      return `The number must be ${max} or less.`;
    // if no errors return empty string
    //dispatch(updateForm(true));
    return "";
  };

  // if email field is valid address
  const validateEmailField = () => {
    if (isRequired && value.length === 0) {
      return blank ?? "An email address is required.";
    }
    // Check the email is valid
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return "The email address is invalid.";
    }
    // if no errors return empty string
    //dispatch(updateForm(true));
    return "";
  };

  const validateDateField = () => {
    // Check for start and end years
    if (start_year) {
      // check the end fdate
    }
  };

  switch (type) {
    case "checkbox":
    case "radio":
    case "text":
    case "select":
      validationError = defaultValidation();
      break;
    case "number":
      validationError = validateNumberField();
      break;
    case "email":
      validationError = validateEmailField();
      break;
    default:
      break;
  }

  return validationError;
};
