import { useSelector } from "react-redux";
export const useFieldCalculation = (fieldId) => {
  const { entities: fields } = useSelector((state) => state.formidable);

  const { field_options, value } = fields[fieldId];

  const calc = field_options?.calc;

  if (calc?.length === 0) return value;

  const regex = /\[([^\][]*)]/g;

  const trimTextFromValue = (value) => {
    const regex = /\d+(?!.*\d)/;
    if (value === 0) return 0;

    if (typeof value !== "string") return value;

    return value.match(regex);
  };

  let match;
  let evalStr = calc;
  let calcValue = 0;

  while ((match = regex.exec(calc))) {
    calcValue = fields[match[1]].value;

    if (Array.isArray(calcValue)) {
      calcValue = calcValue.reduce((subTotal, val) => {
        return subTotal + Number(trimTextFromValue(val));
      }, 0);
    } else {
      calcValue = trimTextFromValue(calcValue) ?? 0;
    }
    evalStr = evalStr.replace("[" + match[1] + "]", calcValue);
  }
  //console.log(evalStr);
  return eval(`${evalStr}`);
};

//[1796]+[1806]-[1801]
