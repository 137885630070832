import React from "react";
import { Checkboxes, Fieldset } from "nhsuk-react-components";

const Checkbox = (props) => {
  const { label, min, max, options, rows, step, value, ...rest } = props;
  return (
    <Fieldset>
      <Fieldset.Legend>{label}</Fieldset.Legend>
      <Checkboxes {...rest}>
        {options.map((option, key) => {
          return (
            <Checkboxes.Box
              value={option.value}
              key={key}
              checked={value.indexOf(option.value) > -1 ? true : false}
            >
              {option.label}
            </Checkboxes.Box>
          );
        })}
      </Checkboxes>
    </Fieldset>
  );
};

export { Checkbox };
