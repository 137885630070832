import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useGetFormsQuery } from "./formidableSlice";

const FormListItem = (form) => {
  const { id, name } = form;
  return (
    <li className="nhsuk-contents-list__item">
      <Link to={`/forms/${id}`} className="nhsuk-contents-list__link">
        {name}
      </Link>
    </li>
  );
};

export const FormsList = () => {
  const {
    data: forms = {},
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetFormsQuery();

  let content;

  if (isLoading) {
    content = <p text="Loading forms..." />;
  } else if (isSuccess) {
    const formList = forms.ids.map((id) => (
      <FormListItem key={id} {...forms.entities[id]} />
    ));

    content = <ol className="nhsuk-contents-list__list">{formList}</ol>;
  } else if (isError) {
    content = <div>{error.toString()}</div>;
  }

  return (
    <div className="nhsuk-width-container">
      <main className="nhsuk-main-wrapper" role="main">
        <h1>Formidable Forms</h1>
        <p>
          Link to NHS stories{" "}
          <a href="https://main--6422e711d7f854c1b681c505.chromatic.com/?path=/docs/welcome--docs">
            repo
          </a>
        </p>
        {content}
      </main>
    </div>
  );
};
