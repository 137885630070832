import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { updateField, selectFieldById } from "./formidableSlice";

import { useFieldCalculation } from "./useFieldCalculation";
import { useFieldCondition } from "./useFieldCondition";
import { useFieldValidation } from "./useFieldValidation";

import {
  Checkbox,
  Date,
  Input,
  Radio,
  Select,
  Textarea,
} from "../../components/fields";

export const Field = (props) => {
  const { fieldId, fieldValidated, page: currentPage } = props;

  // Use hooks to set dynamic values
  const isHidden = useFieldCondition(fieldId);
  const calculatedValue = useFieldCalculation(fieldId);
  const validationError = useFieldValidation(fieldId);

  // The current field
  const field = useSelector((state) => selectFieldById(state, fieldId));

  const { page, type } = field;

  const [value, setFieldValue] = useState(field.value);

  // Validate field and return error
  const [errorMessage, setErrorMessage] = useState("");

  // Calculated value will make field readonly
  const [readonly, setReadOnly] = useState(false);

  const dispatch = useDispatch();

  // Check for field validated
  useEffect(() => {
    dispatch(
      updateField({
        id: fieldId,
        changes: { error: validationError },
      })
    );
  }, [validationError]);

  // Check for field validated
  useEffect(() => {
    if (!fieldValidated) return setErrorMessage("");
    setErrorMessage(validationError);
  }, [fieldValidated]);

  // Update field state value
  const onChangeHandler = (e) => {
    setFieldValue(e.target.value);
  };

  // Dispatch hidden status
  useEffect(() => {
    dispatch(
      updateField({
        id: fieldId,
        changes: { hidden: isHidden },
      })
    );
    if (isHidden && type !== "break") {
      setFieldValue("");
    }
  }, [isHidden]);

  useEffect(() => {
    if (calculatedValue) {
      setFieldValue(calculatedValue);
    }
  }, [calculatedValue]);

  useEffect(() => {
    dispatch(
      updateField({
        id: fieldId,
        changes: { value: value },
      })
    );
  }, [value]);

  // Checkboxes require am array update
  const checkboxHandler = (e) => {
    let newValue = [...value];
    let i = newValue.indexOf(e.target.value);
    if (i > -1) newValue.splice(i, 1);
    else {
      newValue.push(e.target.value);
    }
    setFieldValue(newValue);
  };

  if (isHidden || page != currentPage) return;

  const { error, ...rest } = field;

  switch (type) {
    case "checkbox":
      return (
        <Checkbox
          {...rest}
          value={calculatedValue}
          error={errorMessage}
          onChange={checkboxHandler}
        />
      );

    case "datex":
      return (
        <Date
          {...rest}
          value={calculatedValue}
          error={errorMessage}
          onChange={onChangeHandler}
        />
      );
    case "date":
    case "text":
    case "email":
    case "number":
      return (
        <Input
          {...rest}
          value={calculatedValue}
          error={errorMessage}
          onChange={onChangeHandler}
        />
      );

    case "radio":
      return (
        <Radio
          {...rest}
          value={calculatedValue}
          error={errorMessage}
          onChange={onChangeHandler}
        />
      );

    case "select":
      return (
        <Select
          {...rest}
          value={calculatedValue}
          error={error}
          onChange={onChangeHandler}
        />
      );

    case "textarea":
      return (
        <Textarea
          {...rest}
          value={calculatedValue}
          error={error}
          onChange={onChangeHandler}
        />
      );

    case "divider":
      return;

    default:
    //console.log(`unknown field type (${type})`);
  }
};

export default Field;
